<template>
  <div>
    <v-btn icon color="warning" @click="dialog = true"
      ><v-icon>mdi-pencil</v-icon></v-btn
    >
    <v-dialog v-model="dialog" persistent width="700">
      <v-card class="ma-auto" dark>
        <v-card-title
          ><h2 class="text-h5 mt-3 ml-3 mb-n4">Edit Customer</h2></v-card-title
        >
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-text-field
                label="Customer Name"
                prepend-icon="mdi-account-circle-outline"
                v-model="customer.customer_name"
              ></v-text-field>
              <v-text-field
                label="Customer Contact Number"
                prepend-icon="mdi-phone-outline"
                v-model="customer.customer_contact_no"
              ></v-text-field>
              <v-text-field
                label="Customer TIN"
                prepend-icon="mdi-form-textbox-password"
                v-model="customer.tin"
              ></v-text-field>
              <v-text-field
                label="Other Contact Platform"
                prepend-icon="mdi-facebook-messenger"
                prefix="messenger via -"
                v-model="customer.other_contact_platform"
              ></v-text-field>
              <address-picker
                Heading="Customer Address"
                @extract_address_value="extract"
              />
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="submit" text color="primary" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="reset" text color="warning">Reset</v-btn>
          <v-btn @click="dialog = false" text color="grey">Close</v-btn>
          <v-spacer></v-spacer> </v-card-actions
      ></v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AddressPicker from "@/components/addressPicker.vue";
export default {
  components: { AddressPicker },
  props: ["customer"],
  data() {
    return {
      dialog: false,
      loading: false,
      where_did_you_find_us: [
        "Brochuers and Flyers",
        "Facebook",
        "Radio",
        "Referal",
      ],
    };
  },
  methods: {
    ...mapActions({
      edit_customer: "customer/edit_customer",
    }),
    async submit() {
      this.loading = true;
      await this.edit_customer(this.customer).catch((e) => {
        this.loading = false;
        return alert(e);
      });
      alert("Successfully edited a customer!");
      this.loading = false;
    },
    extract(data) {
      this.customer.customer_province = data[0];
      this.customer.customer_municipality = data[1];
      this.customer.customer_barangay = data[2];
      this.customer.customer_purok = data[3];
      //   console.log(this.form);
    },

    reset() {
      this.$refs.form.reset();
    },
  },
  created() {
    if (this.customer.tin == "") this.customer.tin = "000-000-000";
  },
};
</script>

<style lang="scss" scoped></style>
